import { ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE, EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAILURE } from "../Actiontype";
  
  const initialState = {
    AddorEdituserSuccess: false,
    AddorEdituserSuccessMessage: "",
    AddorEdituserFailure: false,
    AddorEdituserFailureMessage: "",
    UserDetails: null,
  };
  export default function UserManagementReducer(state = initialState, action) {
    switch (action.type) {
      case ADD_USER:
        return {
          ...state,
          isFetching: true,
          AddorEdituserSuccess: false,
          AddorEdituserSuccessMessage: "",
          AddorEdituserFailure: false,
          AddorEdituserFailureMessage: "",
        };
      case ADD_USER_SUCCESS:
        return {
          ...state,
          isFetching: false,
          AddorEdituserSuccess: true,
          AddorEdituserSuccessMessage: action.payload.Message,
        };
      case ADD_USER_FAILURE:
        return {
          ...state,
          isFetching: false,
          AddorEdituserFailure: true,
          AddorEdituserFailureMessage: action.payload.Message,
        };
        case EDIT_USER:
        return {
          ...state,
          isFetching: true,
          AddorEdituserSuccess: false,
          AddorEdituserSuccessMessage: "",
          AddorEdituserFailure: false,
          AddorEdituserFailureMessage: "",
        };
      case EDIT_USER_SUCCESS:
        return {
          ...state,
          isFetching: false,
          AddorEdituserSuccess: true,
          AddorEdituserSuccessMessage: action.payload.Message,
        };
      case EDIT_USER_FAILURE:
        return {
          ...state,
          isFetching: false,
          AddorEdituserFailure: true,
          AddorEdituserFailureMessage: action.payload.Message,
        };
      default:
        return {
          ...state,
        };
    }
  }
  