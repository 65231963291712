import {
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  REMEMBER_ME,
} from "../Actiontype";
const initialState = {
  isFetching: false,
  SignInSuccess: false,
  SignInSuccessMessage: "",
  SignInFailure: false,
  SignInFailureMessage: "",
  SignInData: null,
  rememberMe:false,
  email:''
};
export default function SignInReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isFetching: true,
        SignInSuccess: false,
        SignInSuccessMessage: "",
        SignInFailure: false,
        SignInFailureMessage: "",
        SignInData: null,
        rememberMe:action.rememberMe,
        email:action.rememberMe?action.payload.email:''
      };
    case SIGN_IN_SUCCESS:
      localStorage.setItem("sessionId", action.payload.sessionId);
      localStorage.setItem("adminId",action.payload._id);
      localStorage.setItem('Admin',action.payload.name)
        return {
            ...state,
            isFetching: false,
            SignInSuccess: true,
            SignInSuccessMessage: action.payload.Message,
            SignInData: action.payload
        }
    case SIGN_IN_FAILURE:

      return {
        ...state,
        isFetching: false,
        SignInFailure: true,
        SignInFailureMessage: action.payload.Message,
        SignInData: null
      };
      case REMEMBER_ME:
        return {
          ...state,
          rememberMe: action.rememberMe
        };
    default:
      return {
        ...state
      };
  }
}
