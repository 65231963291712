import { combineEpics } from "redux-observable";
import { SignInEpic } from "./SignInEpic";
import {
  DashboardEpic,
  UserDetailsEpic,
  SubscriptionEpic,
  DeleteUserEpic,
  DeleteDomainEpic,
  LogEpic,
  GetSubUserEpic,
} from "./DashboardEpic";
import { EditUserEpic, AddUserEpic } from "./UserManagementEpic";
import {
  PrivacyPolicyEpic,
  AboutusEpic,
  TermandCondEpic,
  CookiesEpic,
  getTermandCondEpic,
  getAboutusEpic,
  getPrivacyPolicyEpic,
  getCookiesEpic,
  getSubsriptionpolicyEpic,
  SubscriptionpolicyEpic,
  HomedataEpic,
  getHomedataEpic,
  getDashboardcontentEpic,
  DashBoardcontentEpic,
} from "./ContentManageEpic";
import { UpdateAdminProfileEpic, GetAdminProfileEpic, ForgotPassEpic } from "./ProfileEpic";

const rootEpic = combineEpics(
  SignInEpic,
  DashboardEpic,
  UserDetailsEpic,
  SubscriptionEpic,
  DeleteUserEpic,
  DeleteDomainEpic,
  EditUserEpic,
  AddUserEpic,
  PrivacyPolicyEpic,
  AboutusEpic,
  TermandCondEpic,
  CookiesEpic,
  getTermandCondEpic,
  getAboutusEpic,
  getPrivacyPolicyEpic,
  getCookiesEpic,
  UpdateAdminProfileEpic,
  GetAdminProfileEpic,
  ForgotPassEpic,
  getSubsriptionpolicyEpic,
  SubscriptionpolicyEpic,
  HomedataEpic,
  getHomedataEpic,
  GetSubUserEpic,
  getDashboardcontentEpic,
  DashBoardcontentEpic,
  LogEpic
);
export default rootEpic;
