import { ofType } from "redux-observable";
import { switchMap, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";

import { Basepath } from "../../config";
import routeRules from "../../Routes/routeRules";
import  history  from "../../Store/history";
import { toast } from "react-toastify";
import { ADD_USER, EDIT_USER } from "../../Redux/Actiontype";
import { AddUserFailure, EditUserFailure, EditUserSuccess, AddUserSuccess } from "../../Redux/Actions/UsermanageAction";

export const AddUserEpic = action$ =>
action$.pipe(
  ofType(ADD_USER),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/addUser`,action.payload)
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
            history.push(routeRules.usermanagement,toast.success(response.response.Message))
            return of(AddUserSuccess(response.response))
          } else {
            return of(AddUserFailure(response.response));
          }
        })
      )
  )
);

export const EditUserEpic = action$ =>
action$.pipe(
  ofType(EDIT_USER),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/editUser`,action.payload, {
        authentication:localStorage.getItem('sessionId')
      })
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
           return of(EditUserSuccess(response.response))
          } else {
            return of(EditUserFailure(response.response));
          }
        })
      )
  )
);

 
 