import React from "react";
import routeRules from "../../../Routes/routeRules";
import { Link, withRouter } from "react-router-dom";

class LeftNavigation extends React.Component {

  render() {

    return (
      <div className="dashboard-sidenav-left">
        <div className="dashboard-sidenav-left-menu">
          <div className="dashboard-sidenav-left-menu-item-default dashboard-sidenav-left-menu-item-default-w">
            <img
              onClick={() => {
                this.props.history.push(routeRules.usermanagement);
              }}
              src="./assets/images/Dashboard_icon.png"
              alt="dashboardIcon"
            />
            <Link to={routeRules.usermanagement}> User Management </Link>
          </div>
          <div className="dashboard-sidenav-left-menu-item-default dashboard-sidenav-left-menu-item-default-w">
            <img
              onClick={() => {
                this.props.history.push(routeRules.subscriptionmanagement);
              }}
              src="./assets/images/Dashboard_icon.png"
              alt="dashboardIcon"
            />
            <Link to={routeRules.subscriptionmanagement}> Subscription Management </Link>
          </div>
           <div className="dashboard-sidenav-left-menu-item-default dashboard-sidenav-left-menu-item-default-w">
            <img
              onClick={() => {
                this.props.history.push(routeRules.contentmanagement);
              }}
              src="./assets/images/Dashboard_icon.png"
              alt="dashboardIcon"
            />
            <Link to={routeRules.contentmanagement}> Content Management </Link>
          </div>
          <div className="dashboard-sidenav-left-menu-item-default dashboard-sidenav-left-menu-item-default-w">
            <img
              onClick={() => {
                this.props.history.push(routeRules.logManagement);
              }}
              src="./assets/images/Dashboard_icon.png"
              alt="dashboardIcon"
            />
            <Link to={routeRules.logManagement}> Log Management </Link>
          </div>
        </div>
      </div >
    );
  }
}


export default withRouter(LeftNavigation);
