export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";


export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_LOG_LIST = "GET_LOG_LIST";
export const GET_LOG_LIST_SUCCESS = "GET_LOG_LIST_SUCCESS";
export const GET_LOG_LIST_FAILURE = "GET_LOG_LIST_FAILURE";

export const GET_SUB_USER_LIST = "GET_SUB_USER_LIST";
export const GET_SUB_USER_LIST_SUCCESS = "GET_SUB_USER_LIST_SUCCESS";
export const GET_SUB_USER_LIST_FAILURE = "GET_SUB_USER_LIST_FAILURE";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = "GET_SUBSCRIPTION_DETAILS_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_FAILURE = "GET_SUBSCRIPTION_DETAILS_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS";
export const DELETE_DOMAIN_FAILURE = "DELETE_DOMAIN_FAILURE";

export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const PRIVACY_POLICY_SUCCESS = "PRIVACY_POLICY_SUCCESS";
export const PRIVACY_POLICY_FAILURE = "PRIVACY_POLICY_FAILURE";

export const TERM_CONDITION = "TERM_CONDITION";
export const TERM_CONDITION_SUCCESS = "TERM_CONDITION_SUCCESS";
export const TERM_CONDITION_FAILURE = "TERM_CONDITION_FAILURE";

export const COOKIES = "COOKIES";
export const COOKIES_SUCCESS = "COOKIES_SUCCESS";
export const COOKIES_FAILURE = "COOKIES_FAILURE";

export const ABOUT_US = "ABOUT_US";
export const ABOUT_US_SUCCESS = "ABOUT_US_SUCCESS";
export const ABOUT_US_FAILURE = "ABOUT_US_FAILURE";

export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_PRIVACY_POLICY_SUCCESS = "GET_PRIVACY_POLICY_SUCCESS";
export const GET_PRIVACY_POLICY_FAILURE = "GET_PRIVACY_POLICY_FAILURE";

export const GET_TERM_CONDITION = "GET_TERM_CONDITION";
export const GET_TERM_CONDITION_SUCCESS = "GET_TERM_CONDITION_SUCCESS";
export const GET_TERM_CONDITION_FAILURE = "GET_TERM_CONDITION_FAILURE";

export const GET_COOKIES = "GET_COOKIES";
export const GET_COOKIES_SUCCESS = "GET_COOKIES_SUCCESS";
export const GET_COOKIES_FAILURE = "GET_COOKIES_FAILURE";

export const GET_ABOUT_US = "GET_ABOUT_US";
export const GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS";
export const GET_ABOUT_US_FAILURE = "GET_ABOUT_US_FAILURE";

export const SUBSCRIPTION_POLICY = " SUBSCRIPTION_POLICY";
export const SUBSCRIPTION_POLICY_SUCCESS = " SUBSCRIPTION_POLICY_SUCCESS";
export const SUBSCRIPTION_POLICY_FAILURE = " SUBSCRIPTION_POLICY_FAILURE";

export const GET_SUBSCRIPTION_POLICY = "GET_SUBSCRIPTION_POLICY";
export const GET_SUBSCRIPTION_POLICY_SUCCESS = "GET_SUBSCRIPTION_POLICY_SUCCESS";
export const GET_SUBSCRIPTION_POLICY_FAILURE = "GET_SUBSCRIPTION_POLICY_FAILURE";

export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";
export const UPDATE_ADMIN_PROFILE_SUCCESS = "UPDATE_ADMIN_PROFILE_SUCCESS";
export const UPDATE_ADMIN_PROFILE_FAILURE = "UPDATE_ADMIN_PROFILE_FAILURE";

export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export const GET_ADMIN_PROFILE_SUCCESS = "GET_ADMIN_PROFILE_SUCCESS";
export const GET_ADMIN_PROFILE_FAILURE = "GET_ADMIN_PROFILE_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const REMEMBER_ME = 'REMEMBER_ME'

export const UPDATE_HOME_DATA = "UPDATE_HOME_DATA";
export const UPDATE_HOME_DATA_SUCCESS = "UPDATE_HOME_DATA_SUCCESS";
export const UPDATE_HOME_DATA_FAILURE = "UPDATE_HOME_DATA_FAILURE";

export const GET_HOME_DATA = "GET_HOME_DATA";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAILURE = "GET_HOME_DATA_FAILURE";

export const UPDATE_DASHBOARD_CONTENT = "UPDATE_DASHBOARD_CONTENT";
export const UPDATE_DASHBOARD_CONTENT_SUCCESS = "UPDATE_DASHBOARD_CONTENT_SUCCESS";
export const UPDATE_DASHBOARD_CONTENT_FAILURE = "UPDATE_DASHBOARD_CONTENT_FAILURE";

export const GET_DASHBOARD_CONTENT = "GET_DASHBOARD_CONTENT";
export const GET_DASHBOARD_CONTENT_SUCCESS = "GET_DASHBOARD_CONTENT_SUCCESS";
export const GET_DASHBOARD_CONTENT_FAILURE = "GET_DASHBOARD_CONTENT_FAILURE";