const routeRules = {
  landingPage: "/",
  dashboard: "/dashboard",
  userdetails: "/user-detail",
  usermanagement:'/usermanagement',
  logManagement:'/logManagement',
  subscriptioninfo:'/subscriptioninfo',
  subscriptionmanagement:'/subscriptionmanagement',
  domaindetails:'/domaindetails',
  contentmanagement:'/contentmanagement',
  addoredituser:"/addoredituser",
  forget:'/forgot',
  profile:'/profile'

};

export default routeRules;
