import {
  PRIVACY_POLICY,
  PRIVACY_POLICY_SUCCESS,
  PRIVACY_POLICY_FAILURE,
  TERM_CONDITION,
  TERM_CONDITION_SUCCESS,
  TERM_CONDITION_FAILURE,
  COOKIES,
  COOKIES_SUCCESS,
  COOKIES_FAILURE,
  ABOUT_US,
  ABOUT_US_SUCCESS,
  ABOUT_US_FAILURE,
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAILURE,
  GET_TERM_CONDITION,
  GET_TERM_CONDITION_SUCCESS,
  GET_TERM_CONDITION_FAILURE,
  GET_COOKIES,
  GET_COOKIES_SUCCESS,
  GET_COOKIES_FAILURE,
  GET_ABOUT_US,
  GET_ABOUT_US_SUCCESS,
  GET_ABOUT_US_FAILURE,
  GET_SUBSCRIPTION_POLICY,
  GET_SUBSCRIPTION_POLICY_SUCCESS,
  GET_SUBSCRIPTION_POLICY_FAILURE,
  SUBSCRIPTION_POLICY,
  SUBSCRIPTION_POLICY_SUCCESS,
  SUBSCRIPTION_POLICY_FAILURE,
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAILURE,
  UPDATE_HOME_DATA,
  UPDATE_HOME_DATA_SUCCESS,
  UPDATE_HOME_DATA_FAILURE,
  GET_DASHBOARD_CONTENT,
  GET_DASHBOARD_CONTENT_SUCCESS,
  GET_DASHBOARD_CONTENT_FAILURE,
  UPDATE_DASHBOARD_CONTENT,
  UPDATE_DASHBOARD_CONTENT_SUCCESS,
  UPDATE_DASHBOARD_CONTENT_FAILURE,
} from "../Actiontype";

const initialState = {
  ContentManageSuccess: false,
  ContentManageSuccessMessage: "",
  ContentManageFailure: false,
  ContentManageFailureMessage: "",
  data: null,
  cookies: null,
  termcond: null,
  aboutus: null,
  subspolicy: null,
  dashboardcontent:null
};
export default function ContentManagementReducer(state = initialState, action) {
  switch (action.type) {
    case PRIVACY_POLICY:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        data: action.payload.data,
      };
    case PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case TERM_CONDITION:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case TERM_CONDITION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        termcond: action.payload.data,
      };
    case TERM_CONDITION_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case COOKIES:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case COOKIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        cookies: action.payload.data,
      };
    case COOKIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case ABOUT_US:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case ABOUT_US_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        aboutus: action.payload.data,
      };
    case ABOUT_US_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };

    case GET_PRIVACY_POLICY:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        data: action.payload.data,
      };
    case GET_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_TERM_CONDITION:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_TERM_CONDITION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        termcond: action.payload.data,
      };
    case GET_TERM_CONDITION_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_COOKIES:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_COOKIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        cookies: action.payload.data,
      };
    case GET_COOKIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_ABOUT_US:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        aboutus: action.payload.data,
      };
    case GET_ABOUT_US_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_SUBSCRIPTION_POLICY:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_SUBSCRIPTION_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        subspolicy: action.payload.data,
      };
    case GET_SUBSCRIPTION_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case SUBSCRIPTION_POLICY:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case SUBSCRIPTION_POLICY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        subspolicy: action.payload.data,
      };
    case SUBSCRIPTION_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_HOME_DATA:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_HOME_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        homedata: action.payload.data,
      };
    case GET_HOME_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case UPDATE_HOME_DATA:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case UPDATE_HOME_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        homedata: action.payload.data,
      };
    case UPDATE_HOME_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case GET_DASHBOARD_CONTENT:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case GET_DASHBOARD_CONTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        // ContentManageSuccessMessage: action.payload.Message,
        dashboardcontent: action.payload.data,
      };
    case GET_DASHBOARD_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    case UPDATE_DASHBOARD_CONTENT:
      return {
        ...state,
        isFetching: true,
        ContentManageSuccess: false,
        ContentManageSuccessMessage: "",
        ContentManageFailure: false,
        ContentManageFailureMessage: "",
      };
    case UPDATE_DASHBOARD_CONTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ContentManageSuccess: true,
        ContentManageSuccessMessage: action.payload.Message,
        dashboardcontent: action.payload.data,
      };
    case UPDATE_DASHBOARD_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        ContentManageFailure: true,
        ContentManageFailureMessage: action.payload.Message,
      };
    default:
      return {
        ...state,
      };
  }
}
