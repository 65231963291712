import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAILURE,
  GET_SUBSCRIPTION_DETAILS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_DOMAIN,
  DELETE_DOMAIN_SUCCESS,
  DELETE_DOMAIN_FAILURE,
  GET_SUB_USER_LIST,
  GET_SUB_USER_LIST_SUCCESS,
  GET_SUB_USER_LIST_FAILURE,
  GET_LOG_LIST, GET_LOG_LIST_SUCCESS, GET_LOG_LIST_FAILURE
} from "../Actiontype";

const initialState = {
  isFetching: false,
  UserListSuccess: false,
  UserListSuccessMessage: "",
  UserListFailure: false,
  UserListFailureMessage: "",
  UserListData: null,
  DeleteSuccess: false,
  DeleteSuccessMessage: "",
  DeleteFailure: false,
  DeleteFailureMessage: "",
  UserDetails: null,
  SubscribedData: "",
  SubUserListData:null
};
export default function DashBoardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOG_LIST:
      return {
        ...state,
        isFetching: true,
        UserListSuccess: false,
        UserListSuccessMessage: "",
        UserListFailure: false,
        UserListFailureMessage: "",
        UserListData: null,
      };
    case GET_LOG_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        UserListSuccess: true,
        UserListSuccessMessage: action.payload.Message,
        UserListData: action.payload,
      };
    case GET_LOG_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        UserListFailure: true,
        UserListFailureMessage: action.payload.Message,
        UserListData: null,
      };


    case GET_USER_LIST:
      return {
        ...state,
        isFetching: true,
        UserListSuccess: false,
        UserListSuccessMessage: "",
        UserListFailure: false,
        UserListFailureMessage: "",
        UserListData: null,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        UserListSuccess: true,
        UserListSuccessMessage: action.payload.Message,
        UserListData: action.payload,
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        UserListFailure: true,
        UserListFailureMessage: action.payload.Message,
        UserListData: null,
      };
      case GET_SUB_USER_LIST:
        return {
          ...state,
          isFetching: true,
          UserListSuccess: false,
          UserListSuccessMessage: "",
          UserListFailure: false,
          UserListFailureMessage: "",
          SubUserListData: null,
        };
      case GET_SUB_USER_LIST_SUCCESS:
        return {
          ...state,
          isFetching: false,
          UserListSuccess: true,
          UserListSuccessMessage: action.payload.Message,
          SubUserListData: action.payload,
        };
      case GET_SUB_USER_LIST_FAILURE:
        return {
          ...state,
          isFetching: false,
          UserListFailure: true,
          UserListFailureMessage: action.payload.Message,
          SubUserListData: null,
        };
    case GET_USER_DETAILS:
      return {
        ...state,
        isFetching: true,
        UserListSuccess: false,
        UserListSuccessMessage: "",
        UserListFailure: false,
        UserListFailureMessage: "",
        UserDetails: null,
        SubscribedData: "",
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        UserListSuccess: true,
        UserListSuccessMessage: action.payload.Message,
        UserDetails: action.payload,
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        UserListFailure: true,
        UserListFailureMessage: action.payload.Message,
        UserDetails: null,
      };
    case GET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        isFetching: true,
        UserListSuccess: false,
        UserListSuccessMessage: "",
        UserListFailure: false,
        UserListFailureMessage: "",
        SubscribedData: "",
      };
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        UserListSuccess: true,
        UserListSuccessMessage: action.payload.Message,
        SubscribedData: action.payload,
      };
    case GET_SUBSCRIPTION_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        UserListFailure: true,
        UserListFailureMessage: action.payload.Message,
        SubscribedData: "",
      };
    case DELETE_USER:
      return {
        ...state,
        isFetching: true,
        DeleteSuccess: false,
        DeleteSuccessMessage: "",
        DeleteFailure: false,
        DeleteFailureMessage: "",
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        DeleteSuccess: true,
        DeleteSuccessMessage: action.payload.Message,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        DeleteFailure: true,
        DeleteFailureMessage: action.payload.Message,
      };
    case DELETE_DOMAIN:
      return {
        ...state,
        isFetching: true,
        DeleteSuccess: false,
        DeleteSuccessMessage: "",
        DeleteFailure: false,
        DeleteFailureMessage: "",
      };
    case DELETE_DOMAIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        DeleteSuccess: true,
        DeleteSuccessMessage: action.payload.Message,
      };
    case DELETE_DOMAIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        DeleteFailure: true,
        DeleteFailureMessage: action.payload.Message,
      };
    default:
      return {
        ...state,
      };
  }
}
