import loadable from "@loadable/component";
import routeRules from "./routeRules";
const routesData = [
  {
    path: routeRules.landingPage,
    component: loadable(() => import("../Container/Login")),
    auth: false,
    exact: true
  },
  {
    path: routeRules.dashboard,
    component: loadable(() => import("../Container/Dashboard")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.usermanagement,
    component: loadable(() => import("../Container/Usermanagement")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.logManagement,
    component: loadable(() => import("../Container/Logmanagement")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.subscriptionmanagement,
    component: loadable(() => import("../Container/Subsmanagement")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.subscriptioninfo,
    component: loadable(() => import("../Container/SubscriptionDetail")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.userdetails,
    component: loadable(() => import("../Container/UserDetails")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.domaindetails,
    component: loadable(() => import("../Container/DomainInfo")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.contentmanagement,
    component: loadable(() => import("../Container/ContentManagement")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.addoredituser,
    component: loadable(() => import("../Container/Usermanagement/AddorEditUser")),
    auth: true,
    exact: true
  },
  
  {
    path: routeRules.profile,
    component: loadable(() => import("../Container/Profile/index")),
    auth: true,
    exact: true
  },
  {
    path: routeRules.forget,
    component: loadable(() => import("../Container/forgetpass")),
    auth: false,
    exact: true
  },
];

export default routesData;
