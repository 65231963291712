import { ofType } from "redux-observable";
import { switchMap, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import {
  SIGN_IN
} from "../../Redux/Actiontype";
import { Basepath } from "../../config";
import {
  SignInSuccess,
  SignInFailure,
} from "../../Redux/Actions/SignInAction";

export const SignInEpic = action$ =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap(action =>
      ajax
        .post(`${Basepath}/api/admin/login`, action.payload, {
          // authentication:localStorage.getItem('sessionId')
        })
        .pipe(
          mergeMap(response => {
            if (response.response.statusCode === 200) {
              return of(SignInSuccess(response.response));
            } else {
              return of(SignInFailure(response.response));
            }
          })
        )
    )
  );
