import { PRIVACY_POLICY, PRIVACY_POLICY_SUCCESS, PRIVACY_POLICY_FAILURE, TERM_CONDITION, TERM_CONDITION_SUCCESS, COOKIES_SUCCESS, COOKIES_FAILURE, ABOUT_US_SUCCESS, ABOUT_US_FAILURE, TERM_CONDITION_FAILURE, COOKIES, ABOUT_US, GET_PRIVACY_POLICY_FAILURE, GET_TERM_CONDITION, GET_TERM_CONDITION_SUCCESS, GET_COOKIES, GET_COOKIES_SUCCESS, GET_COOKIES_FAILURE, GET_ABOUT_US, GET_ABOUT_US_FAILURE, GET_ABOUT_US_SUCCESS, GET_TERM_CONDITION_FAILURE, GET_PRIVACY_POLICY_SUCCESS, GET_PRIVACY_POLICY, SUBSCRIPTION_POLICY, SUBSCRIPTION_POLICY_SUCCESS, SUBSCRIPTION_POLICY_FAILURE, GET_SUBSCRIPTION_POLICY, GET_SUBSCRIPTION_POLICY_SUCCESS, GET_SUBSCRIPTION_POLICY_FAILURE, UPDATE_HOME_DATA, UPDATE_HOME_DATA_SUCCESS, UPDATE_HOME_DATA_FAILURE, GET_HOME_DATA, GET_HOME_DATA_SUCCESS, GET_HOME_DATA_FAILURE, GET_DASHBOARD_CONTENT, GET_DASHBOARD_CONTENT_SUCCESS, UPDATE_DASHBOARD_CONTENT, UPDATE_DASHBOARD_CONTENT_FAILURE, GET_DASHBOARD_CONTENT_FAILURE } from "../Actiontype";
  
  export const setPrivacyPolicy = (data) => {
    return {
      payload: data,
      type: PRIVACY_POLICY,
    };
  };
  export const PrivacyPolicySuccess = (response) => {
    return {
      payload: response,
      type: PRIVACY_POLICY_SUCCESS,
    };
  };
  export const PrivacyPolicyFailure = (response) => {
    return {
      payload: response,
      type: PRIVACY_POLICY_FAILURE,
    };
  };
  
  
  export const setTermandCond = (data) => {
    return {
      payload: data,
      type: TERM_CONDITION,
    };
  };
  export const setTermandCondSuccess = (response) => {
    return {
      payload: response,
      type: TERM_CONDITION_SUCCESS,
    };
  };
  export const setTermandCondFailure = (response) => {
    return {
      payload: response,
      type: TERM_CONDITION_FAILURE,
    };
  };
  
  
  export const setCookies = (data) => {
    return {
      payload: data,
      type: COOKIES,
    };
  };
  export const setCookiesSuccess = (response) => {
    return {
      payload: response,
      type: COOKIES_SUCCESS,
    };
  };
  export const setCookiesFailure = (response) => {
    return {
      payload: response,
      type: COOKIES_FAILURE,
    };
  };
  
  
  export const setAboutus = (data) => {
    return {
      payload: data,
      type: ABOUT_US,
    };
  };
  export const setAboutusSuccess = (response) => {
    return {
      payload: response,
      type: ABOUT_US_SUCCESS,
    };
  };
  export const setAboutusFailure = (response) => {
    return {
      payload: response,
      type: ABOUT_US_FAILURE,
    };
  };
  
  

  
  export const getPrivacyPolicy = () => {
    return {
      type: GET_PRIVACY_POLICY,
    };
  };
  export const getPrivacyPolicySuccess = (response) => {
    return {
      payload: response,
      type: GET_PRIVACY_POLICY_SUCCESS,
    };
  };
  export const getPrivacyPolicyFailure = (response) => {
    return {
      payload: response,
      type: GET_PRIVACY_POLICY_FAILURE,
    };
  };
  
  
  export const getTermandCond = () => {
    return {
      type: GET_TERM_CONDITION,
    };
  };
  export const getTermandCondSuccess = (response) => {
    return {
      payload: response,
      type: GET_TERM_CONDITION_SUCCESS,
    };
  };
  export const getTermandCondFailure = (response) => {
    return {
      payload: response,
      type: GET_TERM_CONDITION_FAILURE,
    };
  };
  
  
  export const getCookies = () => {
    return {
      type: GET_COOKIES,
    };
  };
  export const getCookiesSuccess = (response) => {
    return {
      payload: response,
      type: GET_COOKIES_SUCCESS,
    };
  };
  export const getCookiesFailure = (response) => {
    return {
      payload: response,
      type: GET_COOKIES_FAILURE,
    };
  };
  
  
  export const getAboutus = () => {
    return {
      type: GET_ABOUT_US,
    };
  };
  export const getAboutusSuccess = (response) => {
    return {
      payload: response,
      type: GET_ABOUT_US_SUCCESS,
    };
  };
  export const getAboutusFailure = (response) => {
    return {
      payload: response,
      type: GET_ABOUT_US_FAILURE,
    };
  };
  
  
  
  export const setSubscriptionpolicy = (data) => {
    return {
      payload: data,
      type: SUBSCRIPTION_POLICY,
    };
  };
  export const setSubscriptionpolicySuccess = (response) => {
    return {
      payload: response,
      type: SUBSCRIPTION_POLICY_SUCCESS,
    };
  };
  export const setSubscriptionpolicyFailure = (response) => {
    return {
      payload: response,
      type: SUBSCRIPTION_POLICY_FAILURE,
    };
  };
  
  
  export const getSubscriptionpolicy = () => {
    return {
      type: GET_SUBSCRIPTION_POLICY,
    };
  };
  export const getSubscriptionpolicySuccess = (response) => {
    return {
      payload: response,
      type: GET_SUBSCRIPTION_POLICY_SUCCESS,
    };
  };
  export const getSubscriptionpolicyFailure = (response) => {
    return {
      payload: response,
      type: GET_SUBSCRIPTION_POLICY_FAILURE,
    };
  };
  
  
  export const setHomedata = (data) => {
    return {
      payload: data,
      type: UPDATE_HOME_DATA,
    };
  };
  export const setHomedataSuccess = (response) => {
    return {
      payload: response,
      type: UPDATE_HOME_DATA_SUCCESS,
    };
  };
  export const setHomedataFailure = (response) => {
    return {
      payload: response,
      type: UPDATE_HOME_DATA_FAILURE,
    };
  };
  
  
  export const getHomedata = () => {
    return {
      type: GET_HOME_DATA,
    };
  };
  export const getHomedataSuccess = (response) => {
    return {
      payload: response,
      type: GET_HOME_DATA_SUCCESS,
    };
  };
  export const getHomedataFailure = (response) => {
    return {
      payload: response,
      type: GET_HOME_DATA_FAILURE,
    };
  };
  
  
  export const setDashboardcontent = (data) => {
    return {
      payload: data,
      type: UPDATE_DASHBOARD_CONTENT,
    };
  };
  export const setDashboardcontentSuccess = (response) => {
    return {
      payload: response,
      type: UPDATE_HOME_DATA_SUCCESS,
    };
  };
  export const setDashboardcontentFailure = (response) => {
    return {
      payload: response,
      type: UPDATE_DASHBOARD_CONTENT_FAILURE,
    };
  };
  
  
  export const getDashboardcontent = () => {
    return {
      type: GET_DASHBOARD_CONTENT,
    };
  };
  export const getDashboardcontentSuccess = (response) => {
    return {
      payload: response,
      type:GET_DASHBOARD_CONTENT_SUCCESS,
    };
  };
  export const getDashboardcontentFailure = (response) => {
    return {
      payload: response,
      type: GET_DASHBOARD_CONTENT_FAILURE,
    };
  };
  