import { ofType } from "redux-observable";
import { switchMap, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import {GET_LOG_LIST,
   GET_USER_LIST, GET_USER_DETAILS, GET_SUBSCRIPTION_DETAILS, DELETE_USER, DELETE_DOMAIN, GET_SUB_USER_LIST
} from "../../Redux/Actiontype";
import { Basepath } from "../../config";
import { getLoglistSuccess, getLoglistFailure,getUserlistSuccess, getUserlistFailure, getUserDetailsSuccess, getUserDetailsFailure, getSubscriptionDetailsSuccess, getSubscriptionDetailsFailure, deleteUserSuccess, deleteUserFailure, deleteDomainSuccess, deleteDomainFailure, getSubUserlistSuccess, getSubUserlistFailure } from "../../Redux/Actions/DashboardAction";
import routeRules from "../../Routes/routeRules";
import  history  from "../../Store/history";
import { toast } from "react-toastify";


export const LogEpic = action$ =>
  action$.pipe(
    ofType(GET_LOG_LIST),
    switchMap(action =>
      ajax
        .get(`${Basepath}/api/admin/getLoglist?${action.payload}`, {
          authentication:localStorage.getItem('sessionId')
        })
        .pipe(
          mergeMap(response => {
            if (response.response.statusCode === 200) {
              return of(getLoglistSuccess(response.response));
            } else {
              return of(getLoglistFailure(response.response));
            }
          })
        )
    )
  );


export const DashboardEpic = action$ =>
  action$.pipe(
    ofType(GET_USER_LIST),
    switchMap(action =>
      ajax
        .get(`${Basepath}/api/admin/userList`, {
          authentication:localStorage.getItem('sessionId')
        })
        .pipe(
          mergeMap(response => {
            if (response.response.statusCode === 200) {
              return of(getUserlistSuccess(response.response));
            } else {
              return of(getUserlistFailure(response.response));
            }
          })
        )
    )
  );

  export const GetSubUserEpic = action$ =>
  action$.pipe(
    ofType(GET_SUB_USER_LIST),
    switchMap(action =>
      ajax
        .get(`${Basepath}/api/admin/getSubscriptionUserList`, {
          authentication:localStorage.getItem('sessionId')
        })
        .pipe(
          mergeMap(response => {
            if (response.response.statusCode === 200) {
              return of(getSubUserlistSuccess(response.response));
            } else {
              return of(getSubUserlistFailure(response.response));
            }
          })
        )
    )
  );

export const UserDetailsEpic = action$ =>
action$.pipe(
  ofType(GET_USER_DETAILS),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/getDomainAcountInfo`,action.payload, {
        authentication:localStorage.getItem('sessionId')
      })
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
            return of(getUserDetailsSuccess(response.response));
          } else {
            return of(getUserDetailsFailure(response.response));
          }
        })
      )
  )
);

 
export const SubscriptionEpic = action$ =>
action$.pipe(
  ofType(GET_SUBSCRIPTION_DETAILS),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/getSubscriptionInfo`,action.payload, {
        authentication:localStorage.getItem('sessionId')
      })
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
            return of(getSubscriptionDetailsSuccess(response.response));
          } else {
            return of(getSubscriptionDetailsFailure(response.response));
          }
        })
      )
  )
);

 
export const DeleteUserEpic = action$ =>
action$.pipe(
  ofType(DELETE_USER),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/deleteUser`,action.payload, {
        authentication:localStorage.getItem('sessionId')
      })
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
            // history.push(routeRules.usermanagement,toast.success(response.response.Message))
            return of(deleteUserSuccess(response.response));
          } else {
            return of(deleteUserFailure(response.response));
          }
        })
      )
  )
);

 
 
export const DeleteDomainEpic = action$ =>
action$.pipe(
  ofType(DELETE_DOMAIN),
  switchMap(action =>
    ajax
      .post(`${Basepath}/api/admin/deleteDomain`,action.payload, {
        authentication:localStorage.getItem('sessionId')
      })
      .pipe(
        mergeMap(response => {
          if (response.response.statusCode === 200) {
            history.push(routeRules.dashboard,toast.success(response.response.Message))
            return of(deleteDomainSuccess(response.response));
          } else {
            return of(deleteDomainFailure(response.response));
          }
        })
      )
  )
);

 