import {
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
} from "../Actiontype";

export const HandleSignIn = (SignInData,rem) => {
  return {
    payload: SignInData,
    rememberMe:rem,
    type: SIGN_IN
  };
};
export const SignInSuccess = response => {
  return {
    payload: response.data,
    type: SIGN_IN_SUCCESS
  };
};
export const SignInFailure = response => {
  return {
    payload: response,
    type: SIGN_IN_FAILURE
  };
};
