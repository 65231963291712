import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
} from "../Actiontype";

export const AddUser = (data) => {
  return {
    payload: data,
    type: ADD_USER,
  };
};
export const AddUserSuccess = (response) => {
  return {
    payload: response,
    type: ADD_USER_SUCCESS,
  };
};
export const AddUserFailure = (response) => {
  return {
    payload: response,
    type: ADD_USER_FAILURE,
  };
};

export const EditUser = (data) => {
  return {
    payload: data,
    type: EDIT_USER,
  };
};
export const EditUserSuccess = (response) => {
  return {
    payload: response,
    type: EDIT_USER_SUCCESS,
  };
};
export const EditUserFailure = (response) => {
  return {
    payload: response,
    type: EDIT_USER_FAILURE,
  };
};
