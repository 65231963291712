import {  GET_LOG_LIST, GET_LOG_LIST_SUCCESS, GET_LOG_LIST_FAILURE,GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE, GET_USER_DETAILS, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE, GET_SUBSCRIPTION_DETAILS_SUCCESS, GET_SUBSCRIPTION_DETAILS_FAILURE, GET_SUBSCRIPTION_DETAILS, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, DELETE_DOMAIN, DELETE_DOMAIN_SUCCESS, DELETE_DOMAIN_FAILURE, GET_SUB_USER_LIST, GET_SUB_USER_LIST_SUCCESS, GET_SUB_USER_LIST_FAILURE } from "../Actiontype";

  
export const getLoglist = (userid) => {
  if (!userid) {
    userid = { domainId: '' }
  }
  return {
    payload: Object.keys(userid)
    .map(key => `${key}=${userid[key]}`)
    .join('&'),
    type: GET_LOG_LIST
  };
};
export const getLoglistSuccess = response => {
  return {
    payload: response.data,
    type: GET_LOG_LIST_SUCCESS
  };
};
export const getLoglistFailure = response => {
  return {
    payload: response,
    type: GET_LOG_LIST_FAILURE
  };
};




  export const getUserlist = () => {
    return {
      type: GET_USER_LIST
    };
  };
  export const getUserlistSuccess = response => {
    return {
      payload: response.data,
      type: GET_USER_LIST_SUCCESS
    };
  };
  export const getUserlistFailure = response => {
    return {
      payload: response,
      type: GET_USER_LIST_FAILURE
    };
  };

  export const getSubUserlist = () => {
    return {
      type: GET_SUB_USER_LIST
    };
  };
  export const getSubUserlistSuccess = response => {
    return {
      payload: response.data,
      type: GET_SUB_USER_LIST_SUCCESS
    };
  };
  export const getSubUserlistFailure = response => {
    return {
      payload: response,
      type: GET_SUB_USER_LIST_FAILURE
    };
  };
  export const getUserDetails = (userid) => {
    return {
      payload:userid,
      type: GET_USER_DETAILS
    };
  };
  export const getUserDetailsSuccess = response => {
    return {
      payload: response.data,
      subscribdata:response.subscription,
      type: GET_USER_DETAILS_SUCCESS
    };
  };
  export const getUserDetailsFailure = response => {
    return {
      payload: response,
      type: GET_USER_DETAILS_FAILURE
    };
  };
  
  
  export const getSubscriptionDetails = (userid) => {
    return {
      payload:userid,
      type: GET_SUBSCRIPTION_DETAILS
    };
  };
  export const getSubscriptionDetailsSuccess = response => {
    return {
      payload: response.data,
      subscribdata:response.subscription,
      type: GET_SUBSCRIPTION_DETAILS_SUCCESS
    };
  };
  export const getSubscriptionDetailsFailure = response => {
    return {
      payload: response,
      type: GET_SUBSCRIPTION_DETAILS_FAILURE
    };
  };
  
  
  export const deleteUser = (userid) => {
    return {
      payload:userid,
      type: DELETE_USER
    };
  };
  export const deleteUserSuccess = response => {
    return {
      payload: response,
      type: DELETE_USER_SUCCESS
    };
  };
  export const deleteUserFailure = response => {
    return {
      payload: response,
      type: DELETE_USER_FAILURE
    };
  };
  
  
  export const deleteDomain = (userid) => {
    return {
      payload:userid,
      type: DELETE_DOMAIN
    };
  };
  export const deleteDomainSuccess = response => {
    return {
      payload: response,
      type: DELETE_DOMAIN_SUCCESS
    };
  };
  export const deleteDomainFailure = response => {
    return {
      payload: response,
      type: DELETE_DOMAIN_FAILURE
    };
  };
  