import { ofType } from "redux-observable";
import { switchMap, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";

import { Basepath } from "../../config";
import routeRules from "../../Routes/routeRules";
import history from "../../Store/history";
import { toast } from "react-toastify";
import {
  PRIVACY_POLICY,
  ABOUT_US,
  TERM_CONDITION,
  COOKIES,
  GET_PRIVACY_POLICY,
  GET_ABOUT_US,
  GET_TERM_CONDITION,
  GET_COOKIES,
  GET_SUBSCRIPTION_POLICY,
  SUBSCRIPTION_POLICY,
  GET_HOME_DATA,
  UPDATE_HOME_DATA,
  GET_DASHBOARD_CONTENT,
  UPDATE_DASHBOARD_CONTENT,
} from "../../Redux/Actiontype";
import {
  PrivacyPolicySuccess,
  PrivacyPolicyFailure,
  setTermandCondSuccess,
  setTermandCondFailure,
  setCookiesSuccess,
  setCookiesFailure,
  setAboutusSuccess,
  setAboutusFailure,
  getCookiesSuccess,
  getCookiesFailure,
  getTermandCondSuccess,
  getTermandCondFailure,
  getAboutusSuccess,
  getAboutusFailure,
  getPrivacyPolicySuccess,
  getPrivacyPolicyFailure,
  getSubscriptionpolicySuccess,
  getSubscriptionpolicyFailure,
  setSubscriptionpolicySuccess,
  setSubscriptionpolicyFailure,
  getHomedataSuccess,
  getHomedataFailure,
  setHomedataSuccess,
  setHomedataFailure,
  getDashboardcontentSuccess,
  getDashboardcontentFailure,
  setDashboardcontentSuccess,
  setDashboardcontentFailure,
} from "../../Redux/Actions/ContentManageAction";
import { getSubscriptionDetailsSuccess } from "../../Redux/Actions/DashboardAction";

export const PrivacyPolicyEpic = (action$) =>
  action$.pipe(
    ofType(PRIVACY_POLICY),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updatePrivacyPolicy`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(PrivacyPolicySuccess(response.response));
            } else {
              return of(PrivacyPolicyFailure(response.response));
            }
          })
        )
    )
  );

export const AboutusEpic = (action$) =>
  action$.pipe(
    ofType(ABOUT_US),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateAboutus`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setAboutusSuccess(response.response));
            } else {
              return of(setAboutusFailure(response.response));
            }
          })
        )
    )
  );

export const TermandCondEpic = (action$) =>
  action$.pipe(
    ofType(TERM_CONDITION),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateTermOfConditions`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setTermandCondSuccess(response.response));
            } else {
              return of(setTermandCondFailure(response.response));
            }
          })
        )
    )
  );

export const CookiesEpic = (action$) =>
  action$.pipe(
    ofType(COOKIES),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateCookiesPolicy`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setCookiesSuccess(response.response));
            } else {
              return of(setCookiesFailure(response.response));
            }
          })
        )
    )
  );

  
export const getPrivacyPolicyEpic = (action$) =>
action$.pipe(
  ofType(GET_PRIVACY_POLICY),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/privacy_policy`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getPrivacyPolicySuccess(response.response));
          } else {
            return of(getPrivacyPolicyFailure(response.response));
          }
        })
      )
  )
);

export const getAboutusEpic = (action$) =>
action$.pipe(
  ofType(GET_ABOUT_US),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/about_us`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getAboutusSuccess(response.response));
          } else {
            return of(getAboutusFailure(response.response));
          }
        })
      )
  )
);

export const getTermandCondEpic = (action$) =>
action$.pipe(
  ofType(GET_TERM_CONDITION),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/terms_conditions`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getTermandCondSuccess(response.response));
          } else {
            return of(getTermandCondFailure(response.response));
          }
        })
      )
  )
);

export const getCookiesEpic = (action$) =>
action$.pipe(
  ofType(GET_COOKIES),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/cookies_policy`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getCookiesSuccess(response.response));
          } else {
            return of(getCookiesFailure(response.response));
          }
        })
      )
  )
);

export const getSubsriptionpolicyEpic = (action$) =>
action$.pipe(
  ofType(GET_SUBSCRIPTION_POLICY),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/subscription`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getSubscriptionpolicySuccess(response.response));
          } else {
            return of(getSubscriptionpolicyFailure(response.response));
          }
        })
      )
  )
);


export const SubscriptionpolicyEpic = (action$) =>
  action$.pipe(
    ofType(SUBSCRIPTION_POLICY),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateSubscription`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setSubscriptionpolicySuccess(response.response));
            } else {
              return of(setSubscriptionpolicyFailure(response.response));
            }
          })
        )
    )
  );

  
export const getHomedataEpic = (action$) =>
action$.pipe(
  ofType(GET_HOME_DATA),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/landingPage`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getHomedataSuccess(response.response));
          } else {
            return of(getHomedataFailure(response.response));
          }
        })
      )
  )
);


export const HomedataEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_HOME_DATA),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateLandingPage`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setHomedataSuccess(response.response));
            } else {
              return of(setHomedataFailure(response.response));
            }
          })
        )
    )
  );

    
export const getDashboardcontentEpic = (action$) =>
action$.pipe(
  ofType(GET_DASHBOARD_CONTENT),
  switchMap((action) =>
    ajax
      .get(`${Basepath}/api/user/getDashBoard`)
      .pipe(
        mergeMap((response) => {
          if (response.response.status === 200) {
            // history.push(
            //   routeRules.dashboard,
            //   toast.success(response.response.Message)
            // );
            return of(getDashboardcontentSuccess(response.response));
          } else {
            return of(getDashboardcontentFailure(response.response));
          }
        })
      )
  )
);


export const DashBoardcontentEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_DASHBOARD_CONTENT),
    switchMap((action) =>
      ajax
        .post(`${Basepath}/api/admin/updateDashboard`, action.payload)
        .pipe(
          mergeMap((response) => {
            if (response.response.statusCode === 200) {
              // history.push(
              //   routeRules.dashboard,
              //   toast.success(response.response.Message)
              // );
              return of(setDashboardcontentSuccess(response.response));
            } else {
              return of(setDashboardcontentFailure(response.response));
            }
          })
        )
    )
  );